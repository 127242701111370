.MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d {
  }
}
.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 {
  position: relative;
  height: 40rem;
  color: #030C22;
  overflow: clip;
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .background-color {
  background-color: white;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  background-size: cover;
  background-position: 50%!important;
  height: 143%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  z-index: -1;
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .header-top-wrapper {
  position: relative;
  height: 6rem;
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 900px) {
  .HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 {
    height: auto;
  }
  .HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .header-top-wrapper {
    height: 9.25rem;
  }
  .HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .background-image {
    background-position: center;
    height: 100%;
  }
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content {
  color: #030c22;
  min-height: 42rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
    .HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .hero-header {
  font-family: Bebas Neue;
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: .15rem;
  text-align: center;
}

@media (min-width: 900px) {
    .HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .hero-header {
        text-align: left;
    }
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .hero-header .external-content p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .hero-subheader {
  letter-spacing: 1.8px;
  line-height: 1.2em;
  margin-bottom: 1rem;
  max-width: 45rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: Montserrat;
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

@media (min-width: 900px) {
    .HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .buttons {
        display: block;
    }
}

.HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 .hero-content .buttons .Button {
  display: inline-block;
  text-transform: uppercase;
  padding: .75rem 3rem;
}
.HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 {
  color: #030C22;
  min-height: 42rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .body {
  letter-spacing: 1.8px;
  line-height: 1.2em;
  margin-bottom: 1rem;
  max-width: 45rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: Montserrat;
}

.HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .button {
  display: flex;
  justify-content: center;
}

.HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  background-color: #eb0a8d;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .title {
    text-align: left;
  }

  .HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 .button {
    display: block;
    justify-content: start;
  }
}
.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .logo {
  width: 8rem;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: 'Bebas Neue';
  font-size: 1.25rem;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #eb0a8d;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .logo {
    width: 9rem;
  }

  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .Navigation {
    display: flex;
  }

  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 .header-top .logo {
    width: 10rem;
  }
}
.PageQuote307b52064f744f78B469B019dbbd8e21 {
  display: flex;
  flex-wrap: wrap;
}
.SecondaryPageDbef23cc63454c00951a08ea58140265 .text-content {
  padding: 0 1rem;
}
.WelcomeBackPopUp {
  padding: 2rem;
  position: relative;
  background-color: white;
  box-shadow: 5px 5px 5px rgb(50 50 50 / 20%);
}

.WelcomeBackPopUp .CloseButton {
  color: black;
}

.WelcomeBackPopUp .BackgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}

.WelcomeBackPopUp .Body {
  position: relative;
}

.gf-area-marketing.WelcomeBackPopUp .Button {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  padding: 0.5rem 2rem;
  text-align: center;
}

.gf-area-marketing.WelcomeBackPopUp .Button:hover {
  color: white;
}

.SideCar {
  background-color: #f9f5e3;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: 'Bebas Neue';
  font-size: 1.25rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  color: #eb0a8d;
  display: block;
}

.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote .image {
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote .TextContent {
  color: #500064;
  font-family: "Bebas Neue";
  font-size: 3rem;
  letter-spacing: 0.1rem;
  line-height: 4rem;
  position: relative;
  padding: 3rem 2rem;
  text-align: center;
  text-shadow: white 0px 0px 4px, white 0px 0px 4px, white 0px 0px 4px, white 0px 0px 4px;
}

.HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote .Title {
  font-family: "Bebas Neue";
  font-size: 350%;
  line-height: 100%;
}

.HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote .Text {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 125%;
}

@media (min-width: 900px) {
  .HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote {
      min-height: 37rem;
  }
  .HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote .TextContent {
    font-size: 4.25rem;
    padding: 4rem;
  }
}

@media (min-width: 1200px) {
  .HomePage6167d8ce37484b7689e36debc35c9cf8 .PageQuote .Title {
  }
}
.SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a {
  min-height: 19rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: clip;
}

.SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a .background-color {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg);
  background-size: cover;
  background-position: center;
  height: 155%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}

.SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a .header-top {
  z-index: 1;
  position: relative;
}

@media (min-height: 900px) {
  .SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a {
    min-height: 24rem;
  }
  .SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a .background-image {
    height: 100%;
  }
}
.SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 {
  color: #030C22;
  flex-grow: 1;
  padding-left: 1.5rem;
}

.SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 .title {
  color: black;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  font-weight: 500;
  line-height: inherit;
}

@media (min-width: 640px) {
  .SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 {
    padding-left: 5rem;
  }

  .SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.MarketingCards3614008991544977B2fe40700293dbe7 {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCards3614008991544977B2fe40700293dbe7 .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

@media (min-width: 768px) {
  .MarketingCards3614008991544977B2fe40700293dbe7 .MarketingCard {
    gap: 6rem;
  }
}

.MarketingCards3614008991544977B2fe40700293dbe7 .MarketingCard .ImageOverlay {
}

@media (min-width: 768px) {
  .MarketingCards3614008991544977B2fe40700293dbe7 .CardTextContent {
    width: 25rem;
  }
}

.MarketingCards3614008991544977B2fe40700293dbe7 .CardHeading {
  color: #500064;
  font-family: Bebas Neue;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingCards3614008991544977B2fe40700293dbe7 .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingCards3614008991544977B2fe40700293dbe7 .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCards3614008991544977B2fe40700293dbe7 .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .MarketingCards3614008991544977B2fe40700293dbe7 .ButtonWrapper {
    justify-content: flex-start;
  }
}

.MarketingCards3614008991544977B2fe40700293dbe7 .Button {
  background-color: #780050;
  color: white;
  font-family: 'Bebas Neue'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}
.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  position: relative;
}

.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .linked-logo {
  display: flex;
  justify-content: center;
}

.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .logo {
  width: 10rem;
}

.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: "Bebas Neue";
  font-size: 1rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #eb0a8d;
}

.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */

@media (min-width: 900px) {
  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede {
    padding: 3em 1.5em 1.5em;
    height: 9rem;
  }

  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .linked-logo {
    flex-grow: 1;
  }
  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .logo {
  }

  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .navigation {
    flex-grow: 4;
  }

  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .Navigation {
    display: flex;
    font-size: 1.25rem;
  }

  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .Navigation {
    font-size: 1.5rem;
  }
  .DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede .logo {
    width: 15.6rem;
  }
}
.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 {
  background-color: #7e2e84;
  color: #ef798a;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 2rem;
  font-family: Montserrat;
}

.gf-customer .DefaultFooter03dd003fF3554fb0B67923afec0b44f3 a {
  color: #ef798a;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 > * {
  text-align: center;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .LogoWrapper {
  font-size: 90%;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .LogoWrapper {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .LogoWrapper:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .GroupCopyright {
  font-size: 90%;
}

.DefaultFooter03dd003fF3554fb0B67923afec0b44f3 .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.MarketingCards995ad6a1A13a4023A6b50e3266860bcd {
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .CardTextContent {
  padding-left: 1rem;
  padding-right: 1rem;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .CardHeading {
  color: #eb0a8d;
  font-family: 'Bebas Neue';
  font-size: 4rem;
  letter-spacing: 0.1rem;
  text-align: center;
  line-height: 1;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .CardText {
  font-family: Montserrat;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .Button {
  background-color: #eb0a8d;
  color: white;
  font-family: Montserrat; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  padding: 0.75rem 3rem;
}

.MarketingCards995ad6a1A13a4023A6b50e3266860bcd .ImageWrapper img {
  max-width: 400px;
}

@media (min-width: 768px) {
  .MarketingCards995ad6a1A13a4023A6b50e3266860bcd .MarketingCard {
    gap: 6rem;
  }

  .MarketingCards995ad6a1A13a4023A6b50e3266860bcd .CardTextContent {
    width: 25rem;
  }

  .MarketingCards995ad6a1A13a4023A6b50e3266860bcd .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }

  .MarketingCards995ad6a1A13a4023A6b50e3266860bcd .ButtonWrapper {
    justify-content: flex-start;
  }
}